'use strict';
var validate = (function() {
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict';
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || key0 == 'title');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          if (data.title === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'title'
              },
              message: 'should have required property \'title\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.title !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '/title',
                schemaPath: '#/properties/title/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {}
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "type": "object",
  "properties": {
    "title": {
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["title"],
  "$schema": "http://json-schema.org/draft-07/schema#"
};
validate.errors = null;
module.exports = validate;
module.exports.schema = {
  "type": "object",
  "properties": {
    "title": {
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": [
    "title"
  ],
  "$schema": "http://json-schema.org/draft-07/schema#"
};