'use strict';
var ucs2length = require('ajv/lib/compile/ucs2length');
var validate = (function() {
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict';
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || key0 == 'message' || key0 == 'threadId');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          var data1 = data.message;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'message'
              },
              message: 'should have required property \'message\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 === "string") {
              if (ucs2length(data1) > 1000) {
                validate.errors = [{
                  keyword: 'maxLength',
                  dataPath: (dataPath || '') + '/message',
                  schemaPath: '#/properties/message/maxLength',
                  params: {
                    limit: 1000
                  },
                  message: 'should NOT be longer than 1000 characters'
                }];
                return false;
              } else {
                if (ucs2length(data1) < 1) {
                  validate.errors = [{
                    keyword: 'minLength',
                    dataPath: (dataPath || '') + '/message',
                    schemaPath: '#/properties/message/minLength',
                    params: {
                      limit: 1
                    },
                    message: 'should NOT be shorter than 1 characters'
                  }];
                  return false;
                } else {}
              }
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '/message',
                schemaPath: '#/properties/message/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            if (errors === errs_1) {}
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.threadId === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'threadId'
                },
                message: 'should have required property \'threadId\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.threadId !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '/threadId',
                  schemaPath: '#/properties/threadId/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {}
          }
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "type": "object",
  "properties": {
    "message": {
      "minLength": 1,
      "maxLength": 1000,
      "type": "string"
    },
    "threadId": {
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["message", "threadId"],
  "$schema": "http://json-schema.org/draft-07/schema#"
};
validate.errors = null;
module.exports = validate;
module.exports.schema = {
  "type": "object",
  "properties": {
    "message": {
      "minLength": 1,
      "maxLength": 1000,
      "type": "string"
    },
    "threadId": {
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": [
    "message",
    "threadId"
  ],
  "$schema": "http://json-schema.org/draft-07/schema#"
};