module.exports = {
  apiKey: "AIzaSyBv0-S-2rb8m-JKs8TwF-T3uKA_knv9fpQ",
  authDomain: "gifygram-site.firebaseapp.com",
  databaseURL: "https://gifygram-site.firebaseio.com",
  projectId: "gifygram-site",
  storageBucket: "gifygram-site.appspot.com",
  messagingSenderId: "780323380600",
  appId: "1:780323380600:web:d8b1b193b0ef86e9fafc8d",
  measurementId: "G-E8PQ0JL7W3"
};
