'use strict';
var ucs2length = require('ajv/lib/compile/ucs2length');
var validate = (function() {
  var refVal = [];
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    'use strict';
    var vErrors = null;
    var errors = 0;
    if (rootData === undefined) rootData = data;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      if (true) {
        var errs__0 = errors;
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !(false || key0 == 'message' || key0 == 'replyId' || key0 == 'title' || key0 == 'width' || key0 == 'height');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
            break;
          }
        }
        if (valid1) {
          var data1 = data.message;
          if (data1 === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'message'
              },
              message: 'should have required property \'message\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data1 === "string") {
              if (ucs2length(data1) > 1000) {
                validate.errors = [{
                  keyword: 'maxLength',
                  dataPath: (dataPath || '') + '/message',
                  schemaPath: '#/properties/message/maxLength',
                  params: {
                    limit: 1000
                  },
                  message: 'should NOT be longer than 1000 characters'
                }];
                return false;
              } else {}
            } else {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '/message',
                schemaPath: '#/properties/message/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            if (errors === errs_1) {}
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            var data1 = data.replyId;
            if (data1 === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'replyId'
                },
                message: 'should have required property \'replyId\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (data1 !== null && typeof data1 !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '/replyId',
                  schemaPath: '#/properties/replyId/type',
                  params: {
                    type: 'null,string'
                  },
                  message: 'should be null,string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              var data1 = data.title;
              if (data1 === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'title'
                  },
                  message: 'should have required property \'title\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data1 === "string") {
                  if (ucs2length(data1) > 26) {
                    validate.errors = [{
                      keyword: 'maxLength',
                      dataPath: (dataPath || '') + '/title',
                      schemaPath: '#/properties/title/maxLength',
                      params: {
                        limit: 26
                      },
                      message: 'should NOT be longer than 26 characters'
                    }];
                    return false;
                  } else {}
                } else {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '/title',
                    schemaPath: '#/properties/title/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                if (errors === errs_1) {}
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                var data1 = data.width;
                if (data1 === undefined) {
                  valid1 = false;
                  validate.errors = [{
                    keyword: 'required',
                    dataPath: (dataPath || '') + "",
                    schemaPath: '#/required',
                    params: {
                      missingProperty: 'width'
                    },
                    message: 'should have required property \'width\''
                  }];
                  return false;
                } else {
                  var errs_1 = errors;
                  if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '/width',
                      schemaPath: '#/properties/width/type',
                      params: {
                        type: 'integer'
                      },
                      message: 'should be integer'
                    }];
                    return false;
                  }
                  if ((typeof data1 === "number")) {
                    if (data1 > 720 || data1 !== data1) {
                      validate.errors = [{
                        keyword: 'maximum',
                        dataPath: (dataPath || '') + '/width',
                        schemaPath: '#/properties/width/maximum',
                        params: {
                          comparison: '<=',
                          limit: 720,
                          exclusive: false
                        },
                        message: 'should be <= 720'
                      }];
                      return false;
                    } else {
                      if (data1 < 1 || data1 !== data1) {
                        validate.errors = [{
                          keyword: 'minimum',
                          dataPath: (dataPath || '') + '/width',
                          schemaPath: '#/properties/width/minimum',
                          params: {
                            comparison: '>=',
                            limit: 1,
                            exclusive: false
                          },
                          message: 'should be >= 1'
                        }];
                        return false;
                      } else {}
                    }
                  }
                  if (errors === errs_1) {}
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.height;
                  if (data1 === undefined) {
                    valid1 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + "",
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'height'
                      },
                      message: 'should have required property \'height\''
                    }];
                    return false;
                  } else {
                    var errs_1 = errors;
                    if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '/height',
                        schemaPath: '#/properties/height/type',
                        params: {
                          type: 'integer'
                        },
                        message: 'should be integer'
                      }];
                      return false;
                    }
                    if ((typeof data1 === "number")) {
                      if (data1 > 720 || data1 !== data1) {
                        validate.errors = [{
                          keyword: 'maximum',
                          dataPath: (dataPath || '') + '/height',
                          schemaPath: '#/properties/height/maximum',
                          params: {
                            comparison: '<=',
                            limit: 720,
                            exclusive: false
                          },
                          message: 'should be <= 720'
                        }];
                        return false;
                      } else {
                        if (data1 < 1 || data1 !== data1) {
                          validate.errors = [{
                            keyword: 'minimum',
                            dataPath: (dataPath || '') + '/height',
                            schemaPath: '#/properties/height/minimum',
                            params: {
                              comparison: '>=',
                              limit: 1,
                              exclusive: false
                            },
                            message: 'should be >= 1'
                          }];
                          return false;
                        } else {}
                      }
                    }
                    if (errors === errs_1) {}
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {}
                }
              }
            }
          }
        }
        if (errs__0 == errors) {}
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    if (errors === 0) {}
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "type": "object",
  "properties": {
    "message": {
      "maxLength": 1000,
      "type": "string"
    },
    "replyId": {
      "type": ["null", "string"]
    },
    "title": {
      "maxLength": 26,
      "type": "string"
    },
    "width": {
      "description": "MAX_VIDEO_SIZE",
      "minimum": 1,
      "maximum": 720,
      "type": "integer"
    },
    "height": {
      "description": "MAX_VIDEO_SIZE",
      "minimum": 1,
      "maximum": 720,
      "type": "integer"
    }
  },
  "additionalProperties": false,
  "required": ["height", "message", "replyId", "title", "width"],
  "$schema": "http://json-schema.org/draft-07/schema#"
};
validate.errors = null;
module.exports = validate;
module.exports.schema = {
  "type": "object",
  "properties": {
    "message": {
      "maxLength": 1000,
      "type": "string"
    },
    "replyId": {
      "type": [
        "null",
        "string"
      ]
    },
    "title": {
      "maxLength": 26,
      "type": "string"
    },
    "width": {
      "description": "MAX_VIDEO_SIZE",
      "minimum": 1,
      "maximum": 720,
      "type": "integer"
    },
    "height": {
      "description": "MAX_VIDEO_SIZE",
      "minimum": 1,
      "maximum": 720,
      "type": "integer"
    }
  },
  "additionalProperties": false,
  "required": [
    "height",
    "message",
    "replyId",
    "title",
    "width"
  ],
  "$schema": "http://json-schema.org/draft-07/schema#"
};